.footer {
    padding: 0 5vw;
    border-top: var(--main-border);
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 20vh;
}

.footer-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
    color: #ff4d4d;
}

.footer-name>p>span {
    color: var(--main-heading);
}

.toggle {
    margin-top: -4vh;
    width: 2rem;
}

.toggle>select {
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    border: var(--main-border);
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: none;
    position: absolute;
}

.footer-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20vw;
    font-size: 1.5rem;
    margin-right: 5vw;
}

.footer-social>a {
    color: var(--main-heading);
    background-color: transparent;
    border: var(--main-border);
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: none;
}

.linkedin:hover {
    background-color: #0077b5;
    color: #fafafa;
}

.github:hover {
    background-color: #333;
    color: #fafafa;
}

.instagram:hover {
    background: linear-gradient(#4f5bd5, #962fbf, #d62976, #fa7e1e, #feda75);
    color: #fafafa;
}

.twitter:hover {
    background-color: #1da1f2;
    color: #fafafa;
}

.top-btn {
    z-index: 100;
    position: fixed;
    bottom: 7vh;
    right: 5vh;
}

.scroll-to-top {
    border: var(--main-border);
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;
}

.scroll-to-top:hover {
    background-color: #ff4d4d;
    color: #fafafa;
}

@media screen and (max-width: 1100px) {
    .top-btn {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 800px) {
    .footer-social {
        width: 30vw;
    }
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 0 1vw;
    }

    .footer-social {
        width: 40vw;
    }
}

@media screen and (max-width: 400px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5vw;
    }

    .footer-name {
        margin-top: 1rem;
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .footer-social {
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 1rem;
    }

    .top-btn {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}