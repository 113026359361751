* {
    transition: all .100s ease-in-out;
    user-select: none;
}

.light-theme {
    --main-heading: #1a1a1a;
    --main-bg-color: #fafafa;
    --main-border: 1px solid #1a1a1a;
    --main-squircles: rgba(0, 0, 0, 0.2);
    --main-bg-color-toggle: #1a1a1a;
    --main-bg-color-scrollbar: #1a1a1a;
}

.dark-theme {
    --main-heading: #fafafa;
    --main-bg-color: #1a1a1a;
    --main-squircles: rgba(255, 255, 255, 0.2);
    --main-border: 1px solid #fafafa;
    --main-bg-color-toggle: #fafafa;
    --main-bg-color-scrollbar: #fafafa;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--main-bg-color);
}

::-webkit-scrollbar {
    width: .4vw;
    height: 1vh;
    background-color: var(--main-bg-color);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--main-bg-color-scrollbar);
}