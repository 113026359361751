.about {
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh 35vw;
    border-radius: 1rem;
}

.about-main>p:first-child,
.about-main-2>p:first-child {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #ff4d4d;
}

.about-main>p:last-child {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    font-family: 'Orbit', sans-serif;
}

hr {
    border: none;
    border-top: 10px dotted var(--main-squircles);
    margin: 2rem 0;
    width: 5vw;
}

.about-main-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh 25vw;
    border-radius: 1rem;
    margin-top: 2rem;
}

.services {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 3rem;
}

.logo {
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: var(--main-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px var(--main-squircles);
    font-size: 2rem;
    color: #ff4d4d7d;
}

.logo>i {
    transition: all .100s ease-in-out;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: 25vw;
}

.content-heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ff4d4d7d;
}

.content-body {
    font-size: 1rem;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: 2px;
    line-height: 1.5;
}

@media screen and (max-width: 900px) {
    .about-main-2 {
        padding: 10vh 15vw;
    }

    .logo {
        width: 10vw;
        height: 10vw;
    }
}

@media screen and (max-width: 600px) {
    .about-main {
        padding: 10vh 15vw;
    }

    .about-main-2 {
        padding: 10vh 10vw;
    }

    .services {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services-hr {
        display: none;
    }

    .logo {
        width: 15vw;
        height: 15vw;
    }

    .content {
        width: 50vw;
    }
}