.projects {
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.project-heading {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: var(--main-heading);
    position: absolute;
    top: 0;
}

.project-container {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.empty {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #ff4d4d;
}

.project {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 20vw;
    height: 40vh;
    margin: 0 1rem;
    padding: 0 1rem 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.project:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.project-image {
    position: absolute;
    top: 0;
    border-radius: 1rem 1rem 0 0;
    width: 100%;
    height: 60%;
    margin-bottom: -3vh;
    transition: all 0.3s ease-in-out;
}

.project-description {
    position: absolute;
    top: calc(60% + 1rem);
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.project-description>p {
    margin: 0.5rem 0;
}

.project-btn {
    position: absolute;
    bottom: 0;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0 0 1rem 1rem;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 20vw;
    background-color: var(--main-heading);
    color: var(--main-bg-color);
    text-decoration: none;
    text-align: center;
}

@media screen and (max-width: 1100px) {

    .project-container {
        gap: 1rem;
    }

    .project {
        width: 30vw;
        height: 40vh;
        margin: 0 0 2rem;
    }

    .project-btn {
        width: 30vw;
    }
}

@media screen and (max-width: 600px) {
    .project-container {
        flex-direction: column;
        gap: 2rem;
    }

    .project {
        width: 60vw;
        height: 40vh;
        margin: 0 0 2rem;
    }

    .project-image {
        height: 50%;
    }

    .project-description {
        top: calc(50% + 1rem);
    }

    .project-btn {
        width: 60vw;
    }
}

@media screen and (max-width: 300px) {
    .project {
        width: 80vw;
        height: 40vh;
        margin: 0 0 2rem;
    }

    .project-image {
        height: 50%;
    }

    .project-description {
        top: calc(50% + 1rem);
    }

    .project-btn {
        width: 80vw;
    }
}