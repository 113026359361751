.contact {
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh 34vw 10vh;
    min-height: 60vh;
}

.contact-heading {
    font-family: sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.contact-subheading {
    font-family: sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 3rem;
    text-align: center;
}

.contact-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.contact-btn {
    text-decoration: none;
    color: var(--main-bg-color);
    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    margin: 0 1rem;
    cursor: pointer;
    background-color: var(--main-heading);
}

.contact-btn>i {
    transition: none;
}

.contact-btn:hover {
    background-color: #ff4d4d;
    color: #fafafa;
}

@media screen and (max-width: 1100px) {
    .contact-heading {
        text-align: center;
    }
}

@media screen and (max-width: 800px) {
    .contact-btn {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 700px) {
    .contact {
        padding: 5vh 20vw 10vh;
    }
}

@media screen and (max-width: 300px) {
    .contact {
        padding: 5vh 10vw 10vh;
    }
}