.navbar {
    width: 100vw;
    min-height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-bg-color);
    color: var(--main-heading);
    padding: 0 2vw;
    position: fixed;
    top: 0;
    z-index: 1;
    border-bottom: var(--main-border);
    transition: all .5s ease-in-out;
}

.navbar-logo {
    color: #ff4d4d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 100%;
    font-size: 4vh;
    font-weight: bold;
}

.theme-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4vw;
    height: 4vw;
    border-radius: 100%;
    background-color: var(--main-bg-color-toggle);
    cursor: pointer;
    transition: .5s;
}

.theme-toggler>span {
    font-size: 2.5vh;
    font-weight: bold;
    cursor: pointer;
}

.navbar-options {
    padding: 0 10vw 0 0;
    width: 30vw;
}

.navbar-options>ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: 100%;
}

.navbar-options>ul>li {
    font-size: 2.5vh;
    font-weight: bold;
    cursor: pointer;
    transition: .5s;
}

.navbar-options>ul>li:hover {
    color: #ff4d4d;
    text-decoration: underline;
}

.hamburger {
    z-index: 10;
    position: absolute;
    right: 5vw;
    top: 5vh;
    display: none;
    font-size: 3vh;
    cursor: pointer;
    transition: .5s;
    margin: 0 8vw;
    border: 1px solid var(--main-heading);
    padding: 1vh 1vw;
    border-radius: 5px;
}

.line {
    width: 3vw;
    height: 2px;
    background-color: var(--main-heading);
    margin: 0.5vh;
    transition: .5s;
}

.active {
    color: #ff4d4d;
}

@media screen and (max-width: 800px) {
    .navbar-options {
        width: 40vw;
        margin-left: -5vw;
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        height: 15vh;
        padding: 0 5vw;
    }

    .navbar-logo {
        width: 20vw;
        font-size: 5vh;
        position: absolute;
        left: 5vw;
    }

    .theme-toggler {
        position: absolute;
        right: 30vw;
        top: 5vh;
        height: 5vh;
        width: 5vh;
    }

    .hamburger {
        display: block;
    }

    .navbar-options {
        display: none;
    }

    .open {
        display: block;
    }

    .navbar-open {
        height: 100vh;
        flex-direction: column;
    }

    .navbar-options>ul {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 5vh;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
    }
}

@media screen and (max-width: 400px) {
    .line {
        width: 4vw;
    }
}

@media screen and (max-width: 400px) {
    .navbar-logo {
        font-size: 4vh;
        margin-left: 5vw;
    }
    .line {
        width: 5vw;
    }
}